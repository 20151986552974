import React, { memo } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { loaded } from '../../utils/helpers';

const GatsbyImage = loadable(() => import('./../common/GatsbyImage'));
const CustomLink = loadable(() => import('./../common/CustomLink'));

const ImageBlock = memo(({ slice }) => {
  const isMobile = loaded && window.innerWidth <= 768;
  const {
    primary: { url, image },
    slice_type,
  } = slice;

  return (
    <section className={slice_type}>
      <CustomLink link={url}>
        {isMobile ? (
          <GatsbyImage image={image?.thumbnails?.mobile} alt={image?.thumbnails?.mobile?.alt} />
        ) : (
          <GatsbyImage image={image} alt={image?.alt} />
        )}
      </CustomLink>
    </section>
  );
});

export default ImageBlock;

export const query = graphql`
  fragment PromotionsPageDataBodyImageBlock on PrismicPromotionsPageDataBodyImageBlock {
    primary {
      url {
        document {
          ... on PrismicBrand {
            data {
              name
              url
            }
          }
          ... on PrismicCategory {
            data {
              name
              url
            }
          }
        }
        url
        type
        uid
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
        alt
        thumbnails {
          mobile {
            gatsbyImageData(layout: FULL_WIDTH)
            alt
          }
        }
      }
    }
  }
`;
